import { gql } from '@apollo/client';

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      firstName
      email
      privilege
      photo
      slug
      _id
    }
  }
`;
