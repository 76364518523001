import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { User } from '../models';
import { CURRENT_USER } from '../graphql/queries';
import { SIGN_OUT } from '../graphql/mutation';

interface IUserContext {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  isLoading?: boolean;
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  isLoading: true,
});

interface UserContextProviderProps {
  children: React.ReactNode;
}

export default function UserContextProvider({
  children,
}: UserContextProviderProps) {
  const { loading, data, error } = useQuery<{
    currentUser: User;
  }>(CURRENT_USER, {
    fetchPolicy: 'network-only',
  });
  const [user, setUser] = React.useState<User>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [signOut] = useMutation(SIGN_OUT);

  React.useEffect(() => {
    if (!loading) {
      if (data?.currentUser) {
        setUser(data.currentUser);
      }
      setLoading(false);
    }
  }, [data, loading]);

  // Handle Logout if the user is admin
  React.useEffect(() => {
    if (user) {
      // Logout an Admin user
      if (user.privilege !== 1) {
        signOut();
        setUser(null);
      }
    }
  }, [user, setUser, signOut]);

  return (
    <>
      <UserContext.Provider
        value={{
          user,
          setUser,
          isLoading,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
}
