/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Router from 'next/router';
import NProgress from 'nprogress';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '../hooks/useApollo';
import { init } from '../lib/sentry';
import AppProvider from '../context/AppProvider';
import AuthProvider from '../context/UserContext';
import '../styles.css';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  init();
}

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

// @ts-ignore
export default function MyApp({ Component, pageProps, err }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <title>Grupo MRE</title>
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="preload"
            as="style"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
            rel="stylesheet"
          />
        </Head>
        <AppProvider>
          <AuthProvider>
            <Component {...pageProps} err={err} />
          </AuthProvider>
        </AppProvider>
      </>
    </ApolloProvider>
  );
}
